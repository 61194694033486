import React, { useState } from "react"
import styled from "styled-components"
import { size, mq, fontFamily } from "../../global.style"
import { getSlug } from "../../pure/String"
import { Button } from "../core/OneHappyButton"
import { WysiWyg } from "../core/WysiWyg"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

export const BigService = ({ service }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Fade>
      <ServiceStyled>
        <Target id={`${getSlug(service.title)}`}></Target>
        <FlexWrap>
          <Wrapper>
            <ImgStyled
              fluid={service.detailImage.asset.fluid}
              alt={service.title}
            ></ImgStyled>
          </Wrapper>
          <Figcaption>
            <h2>{service.title}</h2>
            <Description>
              {service._rawShortMessage && (
                <WysiWyg blocks={service._rawShortMessage} />
              )}
            </Description>
            <Bottom>
              {service._rawShortPrices && (
                <Prices>
                  <WysiWyg blocks={service._rawShortPrices} />
                </Prices>
              )}
              {!showDetails && (
                <OneHappyButtonStyled
                  onClick={e => {
                    setShowDetails(!showDetails)
                  }}
                >
                  Meer info
                </OneHappyButtonStyled>
              )}
            </Bottom>
          </Figcaption>
        </FlexWrap>
        <MainInfo showDetails={showDetails}>
          <Band />
          <DetailedContent>
            <FullDescription>
              {service._rawLongMessage && (
                <WysiWyg blocks={service._rawLongMessage} />
              )}
            </FullDescription>
          </DetailedContent>
          {service._rawPrices && (
            <FullPrices>
              <WysiWyg blocks={service._rawPrices} />
            </FullPrices>
          )}
        </MainInfo>
      </ServiceStyled>
    </Fade>
  )
}

const ServiceStyled = styled.figure`
  background-color: #fff;
  box-shadow: 0 0 0.25rem 0.15rem rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  position: relative;

  ${mq.minWidth(size.landscape)} {
    margin-bottom: 4rem;
    box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.15);
  }
`

const FlexWrap = styled.div`
  ${mq.minWidth(size.portrait)} {
    display: flex;
  }
`

const Wrapper = styled.div`
  ${mq.minWidth(size.portrait)} {
    width: 350px;
    height: 100%;
  }

  ${mq.minWidth("1260px")} {
    width: 450px;
    flex-shrink: 0;
  }
`

const ImgStyled = styled(Img)`
  ${mq.minWidth(size.portrait)} {
    height: 300px;
  }

  ${mq.minWidth("1260px")} {
    height: 450px;
  }
`

const Figcaption = styled.figcaption`
  padding: 1rem;
  width: 100%;
  position: relative;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  ${mq.minWidth(size.phone)} {
    padding: 1.65rem;
  }

  ${mq.minWidth("1260px")} {
    padding: 3rem;
  }

  h2 {
    ${fontFamily("OpenSansExtraBold")};
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;

    ${mq.minWidth(size.phone)} {
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
      line-height: 0.8;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 2rem 0;
      font-size: 2.5rem;
      line-height: 1.2;
    }
  }

  p {
    ${fontFamily("OpenSansSemiBold")};
    margin: 0 0 1rem 0;
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }

    ${mq.minWidth("1260px")} {
      margin: 0;
      line-height: 1.5;
      font-size: 1.5rem;
    }
  }
`

const Prices = styled.div`
  flex-grow: 1;
  margin-bottom: 1rem;
  font-size: 0.8rem;

  ${mq.minWidth(size.landscape)} {
    font-size: 1rem;
  }

  ${mq.minWidth("810px")} {
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      ${fontFamily("OpenSansSemiBold")};

      strong {
        ${fontFamily("OpenSansExtraBold")};
      }

      em {
        font-size: 0.75rem;
      }
    }
  }
`

const Bottom = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.minWidth("810px")} {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
`

const Description = styled.div`
  flex-grow: 1;
`

const MainInfo = styled.section`
  display: ${props => (props.showDetails ? "block" : "none")};
`

const Band = styled.div`
  background-color: #f2f2f2;
  width: 100%;
  height: 1rem;
`

const DetailedContent = styled.div`
  padding: 1rem;

  ${mq.minWidth(size.phone)} {
    padding: 1.65rem;
  }

  ${mq.minWidth("1260px")} {
    padding: 3rem;
  }
`

const FullDescription = styled.div`
  h3 {
    ${fontFamily("OpenSansExtraBold")};
    font-size: 1.25rem;
    margin: 0 0 1rem 0;

    ${mq.minWidth(size.portrait)} {
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 3rem 0;
      font-size: 2rem;
    }
  }

  h4 {
    ${fontFamily("OpenSansExtraBold")};
    font-size: 0.85rem;
    margin: 0 0 1rem 0;

    ${mq.minWidth(size.portrait)} {
      font-size: 1rem;
      margin: 0 0 1rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 3rem 0;
      font-size: 1.2rem;
    }
  }

  blockquote {
    ${fontFamily("OpenSansItalic")};
    margin: 1rem 0;
    padding-left: 1rem;
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansBoldItalic")};
    }

    ${mq.minWidth(size.portrait)} {
      margin: 2.5rem 0;
      padding-left: 2rem;
    }

    ${mq.minWidth("1260px")} {
      margin: 5rem 0;
      line-height: 1.5;
      font-size: 1.5rem;
    }
  }

  li {
    ${mq.minWidth(size.phone)} {
      margin-bottom: 0.5rem;
    }

    ${mq.minWidth("1260px")} {
      margin-bottom: 1rem;
    }
  }

  ol,
  ul,
  p {
    ${fontFamily("OpenSansSemiBold")};
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }

    ${mq.minWidth("1260px")} {
      line-height: 1.5;
      font-size: 1.5rem;
    }
  }
`

const FullPrices = styled(FullDescription)`
  padding: 1rem;
  background-color: #f2f2f2;

  ${mq.minWidth(size.phone)} {
    padding: 1.65rem;
  }

  ${mq.minWidth("1260px")} {
    padding: 3rem;
  }

  blockquote {
    ${fontFamily("OpenSansItalic")};
    margin: 1rem 0;
    padding-left: 1rem;
    font-size: 0.7rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansBoldItalic")};
    }

    ${mq.minWidth(size.portrait)} {
      margin: 2.5rem 0;
      padding-left: 2rem;
    }

    ${mq.minWidth("1260px")} {
      margin: 5rem 0;
      line-height: 1.5;
      font-size: 1.3rem;
    }
  }

  strong {
    ${fontFamily("OpenSansExtraBold")};
  }

  ol,
  ul,
  p {
    ${fontFamily("OpenSansSemiBold")};
    font-size: 0.7rem;
    line-height: 1.6;

    ${mq.minWidth("1260px")} {
      line-height: 1.5;
      font-size: 1.3rem;
    }
  }

  li {
    ${mq.minWidth("1260px")} {
      margin-bottom: 0rem;
    }
  }

  h3 {
    ${mq.minWidth(size.phone)} {
      margin: 0 0 0rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 0 0;
    }
  }

  h4 {
    ${mq.minWidth(size.phone)} {
      margin: 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 1rem 0;
    }
  }
`

const OneHappyButtonStyled = styled(Button)`
  align-self: flex-end;

  ${mq.minWidth("810px")} {
    align-self: center;
  }
`

const Target = styled.div`
  position: absolute;
  top: -2.5rem;
  
  ${mq.minWidth(size.landscape)} {
    top: -8rem;
`
