import styled from "styled-components"
import { colors, mq } from "../../global.style"
import { Link } from "gatsby"

export const OneHappyButton = styled(Link)`
  text-decoration: none;
  appearance: none;
  border: 0;
  background-color: ${props =>
    props.mainColor ? props.mainColor : colors.sec};
  color: ${props => (props.textColor ? props.textColor : "#fff")};
  font-family: "OpenSansExtraBoldItalic";
  font-size: 1rem;
  padding: 0.4rem 1rem;
  border-radius: 2rem;
  transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: scale(1.05);
  }

  ${mq.minWidth("1260px")} {
    font-size: 1.375rem;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem 0.6rem;
  }
`

export const Button = styled.button`
  text-decoration: none;
  appearance: none;
  outline: none;
  border: 0;
  background-color: ${props =>
    props.mainColor ? props.mainColor : colors.sec};
  color: ${props => (props.textColor ? props.textColor : "#fff")};
  font-family: "OpenSansExtraBoldItalic";
  font-size: 1rem;
  padding: 0.4rem 1rem;
  border-radius: 2rem;
  transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: scale(1.05);
  }

  ${mq.minWidth("1260px")} {
    font-size: 1.375rem;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem 0.6rem;
  }
`
