import React from "react"
import styled from "styled-components"
import { OneHappyButton } from "../core/OneHappyButton"
import { colors, fontFamily, size, mq } from "../../global.style"
import Fade from "react-reveal/Fade"

export const Quote = () => {
  return (
    <QuoteStyled>
      <Fade left>
        <FlexWrap>
          <Text>Meer dan 15 jaar professionele ervaring met honden</Text>
          <OneHappyButtonStyled
            to="/team"
            mainColor={"#FFF"}
            textColor={colors.primDark}
          >
            Ontmoet het Team
          </OneHappyButtonStyled>
        </FlexWrap>
      </Fade>
    </QuoteStyled>
  )
}

const QuoteStyled = styled.blockquote`
  background-color: ${colors.primDark};
  margin: 0;
  padding: 1.5rem 1rem;

  ${mq.minWidth(size.portrait)} {
    padding: 2rem;
  }
`

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.minWidth("805px")} {
    flex-direction: row;
    align-items: center;
  }
`

const OneHappyButtonStyled = styled(OneHappyButton)`
  align-self: flex-end;
  ${mq.minWidth("805px")} {
    align-self: center;
  }
`

const Text = styled.p`
  ${fontFamily("OpenSansExtraBoldItalic")};
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;

  ${mq.minWidth(size.portrait)} {
    margin-bottom: 0;
    line-height: 3;
    font-size: 1.2rem;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
  }

  ${mq.minWidth(size.desktop)} {
    font-size: 2.75rem;
  }

  span {
    ${fontFamily("OpenSansItalic")};
    font-size: 1.85rem;
    line-height: 1;

    ${mq.minWidth(size.portrait)} {
      font-size: 3.25rem;
    }

    ${mq.minWidth(size.laptop)} {
      font-size: 5.25rem;
    }

    ${mq.minWidth(size.desktop)} {
      font-size: 6.25rem;
    }
  }
`
