import React from "react"
import styled from "styled-components"
import { BigService } from "../entity/BigService"
import { Container } from "../core/Container"
import { size, mq } from "../../global.style"

export const BigServices = ({ bigservices }) => {
  return (
    <BigServicesStyled>
      <Container>
        {bigservices.nodes &&
          bigservices.nodes.map(service => (
            <BigService
              key={`Service__${service.id}`}
              service={service}
            ></BigService>
          ))}
      </Container>
    </BigServicesStyled>
  )
}

const BigServicesStyled = styled.div`
  padding: 1rem 0;
  background-color: #fff;

  ${mq.minWidth(size.landscape)} {
    padding: 5rem 0;
  }
`
