import React from "react"
import Layout from "../Layout"
import { BigServices } from "../components/rings/BigServices"
import { Quote } from "../components/rings/Quote"
import { useStaticQuery, graphql } from "gatsby"
import { OrderForm } from "../components/rings/OrderForm"
import { Seo } from "../components/Seo"

export default function Formules() {
  const dataForumula = useStaticQuery(graphql`
    query Formulas {
      allSanityFormula(sort: { fields: _createdAt }) {
        nodes {
          id
          title
          _rawShortMessage
          _rawLongMessage
          _rawShortPrices
          _rawPrices
          detailImage {
            asset {
              fluid(maxWidth: 430) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/formules" />
      <BigServices bigservices={dataForumula.allSanityFormula}></BigServices>
      <Quote />
      <OrderForm services={dataForumula.allSanityFormula}></OrderForm>
    </Layout>
  )
}
